body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;  /* Ensure no default margin */
  font-family: Arial, sans-serif;  /* Optional: Set a default font */
}

.App {
  background-image: url(../src/assets/background.png);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding-top: 50px;
}

.hercode-logo img {
  width: 300px; 
}

.hercode-logo{
  justify-content: center;
  align-items: center;
  margin: auto;
}

.main {
  margin-top: 200px;
  text-align: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

h1 {
  font-size: 7rem;
  margin: 20px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.contact-button {
  display: inline-block;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 20px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 50px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-button:hover {
  background-color: #00000053;
  transform: scale(1.05);
}


.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.social-icons a img {
  width: 40px; 
  height: 40px;
  transition: transform 0.3s, filter 0.3s;
}

.social-icons a:hover img {
  transform: scale(1.2);
  filter: brightness(1.2);
}

footer {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.568);
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}


/* Responsive Styles */
@media (max-width: 1024px) {
  .hercode-logo img {
    width: 250px;
  }

  h1 {
    font-size: 5rem;
  }

  .contact-button {
    font-size: 1.2rem;
    padding: 15px 20px;
  }

  .social-icons a img {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 768px) {
  .main {
    margin-top: 10px;
  }
  .hercode-logo img {
    width: 200px;
  }

  h1 {
    font-size: 4rem;
  }

  .contact-button {
    font-size: 1rem;
    padding: 10px 15px;
    margin-top: 40px;
  }

  .social-icons a img {
    width: 50px;
    height: 50px;
  }

  .App-header {
    padding-top: 30px;
  }

  footer {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .App{
    background-image: url(../src/assets/background2.png);
  }

  .main {
    margin-top: 0px;
  }

  .hercode-logo img {
    width: 150px;
  }

  h1 {
    font-size: 4rem;
  }

  .contact-button {
    font-size: 1rem;
    padding: 8px 12px;
    margin-top: 20px;
  }

  .social-icons a img {
    width: 40px;
    height: 40px;
  }

  footer {
    font-size: 0.8rem;
  }
}